// Page URL'S
export enum PAGE_URLS {
  ERROR_PAGE = "error/:errorCode",
  HOME = "/home",
  LOGIN = "/login",
  OTP_VERIFICATION = "/otp-verification",
  RESET_PASSWORD = "/reset-password/:token",
  HEALTH = "/health/",
  ORDERS_DEATAILS = "/orders-details",
  REPORTS = '/resend',
  TOPUP = '/topup-history',
  TOPUP_PAGE = '/topup-history/add-topup',
  API_CREDENTIALS = '/api-credential-management',
  CREATE_API = '/api-credential-management/create-api',
  SETTINGS= '/settings',
  SETTINGS_CHANGE_PASSWORD= '/settings/change-password'
}

export enum PAYMENT_TYPES {
  CHEQUE = 'cheque',
  BANK_TRANSFER = 'bank_transfer'
}

export const REGEX = {
  NUMBER_ONLY: /^[0-9\b]+$/,
}

export const OTP_RESEND_INTERVAL_S = 30; 
export const ALERT_DELAY = 10000;