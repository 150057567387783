import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import './style.less';

/**
 * @method OrderAlertMessage
 * @description order page initial message alert
 * @returns {JSX.Element}
 */

const OrderAlertMessage = ({
  show,
  handleCloseAlertMessage,
}: {
  show: boolean;
  handleCloseAlertMessage: (value: boolean) => void;
}): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setVisible(show);
    }, 0);
  }, [show]);

  const handleClose = () => {
    handleCloseAlertMessage(true);
  };

  return (
    <div className={`alert-message ${visible ? 'show' : ''}`}>
      <p>
        Orders will appear after 30 minutes of processing.{' '}
        <span onClick={handleClose}>
          <CloseCircleOutlined
            style={{ fontSize: '16px', cursor: 'pointer', paddingLeft: '5px' }}
          />
        </span>
      </p>
    </div>
  );
};

export default OrderAlertMessage;
