import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "@redux/store";
import { IErrorMessage, UserProfileData, UserPopoverData, IHelplineNumber } from 'interfaces/common.interface';

export interface CommonSlice {
  isLoading: boolean;
  errorMessage: IErrorMessage;
  userData: UserProfileData;
  userPopoverData: UserPopoverData;
  helpline: {
    mobileNumbers: IHelplineNumber[] | null,
    email: string
  }
}

const initialState: CommonSlice = {
  isLoading: false,
  errorMessage: {
    title: "",
    message: "",
    isShown: false
  },
  userData: {
    email: "",
    corporate_name: "",
    corporate_logo_url: "",
    mobile_number: "",
    cred_management_flag: false,
    balance_visibility_flag: false,
    view_resend:false,
   view_topup:false,
   view_api_credentials:false,
   view_and_add_topup:false,
   view_and_add_api_credentials:false
  },
  userPopoverData: {
    first_name: "",
    last_name: "",
  },
  helpline: {
    mobileNumbers: null,
    email: ""
  },
};

// #. Common slice contains the common state methods
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCommonErrorMessage: (state, action: PayloadAction<IErrorMessage>) => {
      state.errorMessage = action.payload;
    },
    setUserProfileData: (state, action: PayloadAction<UserProfileData>) => {
      state.userData = action.payload;
    },
    setUserPopoverData: (state, action: PayloadAction<UserPopoverData>) => {
      state.userPopoverData = action.payload;
    },
    setHelplineData: (state, action: PayloadAction<IHelplineNumber[]>) => {
      state.helpline = {
        mobileNumbers: action.payload,
        email: action.payload[0].help_desk_email
      };
    },
  },
});

export const { showLoader, setCommonErrorMessage, setUserProfileData, setUserPopoverData, setHelplineData } = commonSlice.actions;

// #. State for loader component
export const selectIsLoading = (state: AppState) => state.common.isLoading;

// #. Export the reducers
export default commonSlice.reducer;